import { React } from '@/app'
import { View, Link, LanguageSelector, LanguageOptions, Button, Text } from '@/components'
import { AnyFunction, StylesOf, useI18N } from '@codeleap/common'
import { HeaderComposition } from '../../app/stylesheets/Header'
import { NavigationUtils } from '@/utils'
import { ButtonProps } from '@codeleap/web'

export type NavContentProps = {
  isMobile?: boolean
  variantStyles?: StylesOf<HeaderComposition>
  visibleLanguageOptions: boolean
  toggleLanguageOptions: AnyFunction
}

type NavigateLink = {
  text: string
  route?: AppRoute
  to?: string
}

export const NavContent = (props: NavContentProps) => {
  const { variantStyles, isMobile, visibleLanguageOptions, toggleLanguageOptions } = props

  const { t, locale } = useI18N()

  const links: (NavigateLink | Omit<ButtonProps, 'debugName'>)[] = React.useMemo(
    () => [
      { text: t('header.home'), route: 'Home' },
      { text: t('header.about'), route: 'About' },
      { text: t('header.contact'), route: 'Contact' },
      isMobile ? { text: t('header.language'), onPress: toggleLanguageOptions } : null,
    ].filter(Boolean),
    [isMobile, locale],
  )

  const renderLink = React.useCallback((link: NavigateLink | Omit<ButtonProps, 'debugName'>) => {
    // @ts-ignore
    if (link?.onPress) {
      const _link = link as ButtonProps
      return (
        <Button
          variants={['minimal', 'alignCenter', 'fullWidth']}
          text={_link?.text}
          debugName={'nav link'}
          onPress={_link?.onPress}
        />
      )
    }

    const _link = link as NavigateLink
    const isSelected = NavigationUtils.isCurrentRoute(_link?.route)

    return (
      <Link
        key={'header-' + _link?.text}
        text={_link?.text}
        route={_link?.route}
        to={_link?.to}
        css={[variantStyles?.navItem, isSelected ? variantStyles['navItem:selected'] : {}]}
      />
    )
  }, [])

  if (visibleLanguageOptions) {
    return (
      <View variants={['column', 'paddingVertical:5', 'gap:4']}>
        <Text variants={['h2']} text={t('languageDescription')} />
        <LanguageOptions />
      </View>
    )
  }

  return (
    <View css={variantStyles.navContentWrapper}>
      {links?.map(renderLink)}
      {isMobile ? null : <LanguageSelector />}
    </View>
  )
}
