import './services/firebaseApp'

import { StyleProvider, ReactQuery, I18NProvider, useI18N, onUpdate } from '@codeleap/common'
import { Settings, variantProvider, React, I18N } from './app'
import { AppStatusOverlay, DebugModal, GlobalAlert, GlobalStyle, languageOptions, Onboarding, ShareModal, variants } from './components'
import { Provider } from 'react-redux'
import { store } from './redux'
import { APIClient } from './services'
import { I18NDefaultLocale } from './app/i18n'

const App = ({ children }) => {
  APIClient.Session.useSession(true)
  const { locale, setLocale } = useI18N()

  onUpdate(() => {
    const isValidLocale = languageOptions.find(l => l.value === locale)

    if (!isValidLocale) setLocale(I18NDefaultLocale)
  }, [locale])

  return (
    <>
      <GlobalStyle />
      {children}
      <AppStatusOverlay />
      <GlobalAlert />
      <DebugModal />
      <Onboarding />
      <ShareModal />
    </>
  )
}

export const Root = ({ children }) => {

  return (
    <ReactQuery.QueryClientProvider client={APIClient.queryClient.client}>
      <Provider store={store}>
        <StyleProvider settings={Settings} variantProvider={variantProvider} variants={variants} logger={logger}>
          <I18NProvider i18n={I18N}>
            <App>{children}</App>
          </I18NProvider>
        </StyleProvider>
      </Provider>
    </ReactQuery.QueryClientProvider>
  )
}
