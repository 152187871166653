import { getSimilarLocale, make18n } from '@codeleap/common'
import { IS_SSR } from '../constants'
import { LocalStorageKeys } from '../localStorage'

import { Paths } from '@codeleap/common/dist/types/pathMapping'

import de from './de/de.json'
import enGB from './en-GB/en-GB.json'
import fr from './fr/fr.json'

// import it from './it/it.json'
// import ptBR from './pt-BR/pt-BR.json'
// import ptPT from './pt-PT/pt-PT.json'

export const I18NDefaultLocale = 'fr'

export const I18NDictionary = {
  de,
  'en-GB': enGB,
  fr,

  // it,
  // 'pt-BR': ptBR,
  // 'pt-PT': ptPT,
}

type I18NKeys = Paths<typeof I18NDictionary['en-GB']>

const storage = () => {
  if (IS_SSR) return null

  return localStorage
}

const getLocale = () => {
  if (IS_SSR) return I18NDefaultLocale

  return navigator.language
}

export const deviceLocale = getLocale()
export const initialLocale = getSimilarLocale(deviceLocale, I18NDefaultLocale, I18NDictionary)

export const I18N = make18n<I18NKeys>({
  initialLocale,
  persistor: {
    getLocale: async () => storage()?.getItem(LocalStorageKeys.LOCALE),
    setLocale: async (locale) => storage()?.setItem(LocalStorageKeys.LOCALE, locale),
  },
  languageDictionary: I18NDictionary,
})
