import { IS_SSR, routes } from '@/app'

function isCurrentRoute(route) {
  if (IS_SSR) return

  const currentPath = (window?.location.pathname ?? '')
    .replace(/\/[a-zA-Z]{2}(?:-[a-zA-Z]{2})?\//, '')
    .replace(/^\/+/, '')
  const path = `${routes[route]}/`

  return !!currentPath.length ? currentPath === path : '//' === path
}

function getSearchParams() {
  if (IS_SSR) return {}

  const searchParams = new URLSearchParams(window.location.search)

  return Object.fromEntries(searchParams)
}

export const NavigationUtils = {
  isCurrentRoute,
  getSearchParams,
}
