import { AppIcon } from '@/app'
import { JobCardsComposition, AppJobCardsStyles } from '@/app/stylesheets/JobCards'
import { Icon, Image, Text, View } from '@/components'
import { ComponentVariants, useDefaultComponentStyle, useI18N, useMemo } from '@codeleap/common'
import { StylesOf } from '@codeleap/web'

type JobCardProps = {
  title: string
  image: any
  icon: AppIcon
}

type JobCardsProps = ComponentVariants<typeof AppJobCardsStyles> & {
  styles?: StylesOf<JobCardsComposition>
  cards: JobCardProps[]
}

export function JobCards({ cards, variants, responsiveVariants, styles, ...props }: JobCardsProps) {
  const { locale } = useI18N()

  const variantStyles = useDefaultComponentStyle<'u:JobCards', typeof AppJobCardsStyles>('u:JobCards', {
    variants,
    responsiveVariants,
    styles,
  })

  const renderCard = (card: JobCardProps, index: number) => useMemo(
    () => (
      <View key={`timeline-card-${card.title}-${index}`} css={variantStyles.cardWrapper}>
        <Image source={card.image} style={variantStyles.cardImageWrapper} objectFit='cover' />
        <View style={variantStyles.cardInnerWrapper}>
          <Icon name={card.icon} debugName='icon-card' style={variantStyles.cardIcon} />
          <Text style={variantStyles.cardTitle} text={card.title} />
        </View>
      </View>
    ),
    [locale],
  )

  return (
    <View style={variantStyles.wrapper} {...props}>
      {cards.map(renderCard)}
    </View>
  )
}
