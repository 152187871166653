import { AppImages, assignTextStyle, I18N, Settings, variantProvider } from '@/app'
import { View, Image, Button, Text } from '@/components'
import { MOBILE_BREAKPOINT, MID_BREAKPOINT, LARGE_BREAKPOINT } from '@/app'

function StoreButton({ storeLink, image }) {
  function openLink() {
    window.open(storeLink, '_blank')
  }
  return (
    <Button css={styles.button} debugName='store-btn' onPress={openLink}>
      <Image source={image} css={styles.image} />
    </Button>
  )
}

export function FooterStores() {
  return (
    <View css={styles.wrapper}>
      <Text css={styles.title} variants={['h0']} text={I18N.t('footer.hero')} />
      <View css={styles.innerWrapper}>
        <StoreButton
          image={AppImages.AppStore}
          storeLink={Settings.Stores.Apple.url}
        />
        <StoreButton
          image={AppImages.GooglePlay}
          storeLink={Settings.Stores.Google.url}
        />
      </View>
    </View>
  )
}

const styles = variantProvider.createComponentStyle(theme => ({
  wrapper: {
    ...theme.presets.column,
    ...theme.spacing.gap(5),

    [theme.media.down(LARGE_BREAKPOINT)]: {
      width: theme.spacing.value(44),
    },

    [theme.media.down(MID_BREAKPOINT)]: {
      width: theme.spacing.value(36),
    },

    [theme.media.down(MOBILE_BREAKPOINT)]: {
      ...theme.presets.fullWidth,
    },
  },

  title: {
    ...assignTextStyle('h0')(theme).text,
    color: theme.colors.neutral1,

    [theme.media.down(LARGE_BREAKPOINT)]: {
      ...assignTextStyle('h1')(theme).text,
      color: theme.colors.neutral1,
    },

    [theme.media.down(MOBILE_BREAKPOINT)]: {
      ...theme.presets.textCenter,
    },
  },

  innerWrapper: {
    ...theme.spacing.gap(2.5),

    [theme.media.down(MOBILE_BREAKPOINT)]: {
      ...theme.presets.center,
    },
  },

  button: {
    height: theme.spacing.value(7),
    ...theme.spacing.padding(1),
    ...theme.spacing.paddingHorizontal(2),
    backgroundColor: theme.colors.neutral1,

    '&:hover': {
      backgroundColor: theme.colors.neutral3,
    },

    [theme.media.down(MID_BREAKPOINT)]: {
      height: theme.spacing.value(5),
      ...theme.spacing.paddingHorizontal(1.5),
    },

    [theme.media.down(MOBILE_BREAKPOINT)]: {
      height: theme.spacing.value(7),
      ...theme.spacing.paddingHorizontal(2),
    },
  },

  image: {
    height: theme.spacing.value(4),

    [theme.media.down(MID_BREAKPOINT)]: {
      height: theme.spacing.value(2.5),
    },

    [theme.media.down(MOBILE_BREAKPOINT)]: {
      height: 'unset',
      ...theme.presets.fullWidth,
    },
  },
}), true)
