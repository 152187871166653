import { DotTimelineComposition, AppDotTimelineStyles } from '@/app/stylesheets/DotTimeline'
import { Text, View } from '@/components'
import { ComponentVariants, useDefaultComponentStyle, useI18N, useMemo } from '@codeleap/common'
import { StylesOf } from '@codeleap/web'

type TimelineItemProps = {
  title: string
  description: string
}

type DotTimelineProps = ComponentVariants<typeof AppDotTimelineStyles> & {
  styles?: StylesOf<DotTimelineComposition>
  items: TimelineItemProps[]
}

export function DotTimeline({ items, variants, responsiveVariants, styles, ...props }: DotTimelineProps) {
  const { locale } = useI18N()
  const variantStyles = useDefaultComponentStyle<'u:DotTimeline', typeof AppDotTimelineStyles>('u:DotTimeline', {
    variants,
    responsiveVariants,
    styles,
  })

  const renderItem = (item: TimelineItemProps, index: number) => useMemo(
    () => (
      <View key={`timeline-item-${item.title}-${index}`} css={[variantStyles.itemWrapper, index === items.length - 1 && variantStyles['itemWrapper:last']]}>
        <View style={variantStyles.itemDot} />
        <View style={variantStyles.itemInnerWrapper}>
          <Text style={variantStyles.itemTitle} text={item.title} />
          <Text style={variantStyles.itemDescription} text={item.description} />
        </View>
      </View>
    ),
    [locale],
  )

  return (
    <View style={variantStyles.wrapper} {...props}>
      <View css={[variantStyles.innerWrapper]}>
        <View style={variantStyles.line} />
        {items.map(renderItem)}
      </View>
    </View>
  )
}
